<template>
  <div class="login-box">
    <div class="login-logo">
      <b>
        {{ t("info.name") }}
      </b>
      Admin
      <!-- <img src="images/admin_logo.png" style="width: 80%;"> -->
    </div>
    <LoginForm />
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import LoginForm from "./LoginForm.vue";
import store from "@/store";

store.commit("app/UPDATE_LOADING", false);

const { t } = useI18n();
</script>

<style lang="scss" scoped>
.login-box {
  width: 360px;
  margin: 7% auto;

  .login-logo {
    font-size: 35px;
    text-align: center;
    margin-bottom: 25px;
    font-weight: 300;

    b {
      font-weight: 700;
    }
  }

  .login-logo a {
    color: #444;
  }
}

@media (max-width: 768px) {
  .login-box {
    width: 90%;
    margin-top: 20px;
  }
}
</style>
