<template>
  <header>
    <button type="button" @click="changeMenu" class="menu">
      <span class="material-symbols-outlined"> menu </span>
    </button>
    <div class="funcBtn">
      <Locale /> |
      <button type="button" @click="logout" class="logout">
        <span class="material-symbols-outlined"> lock </span>
        <span>{{ t("common.logout") }}</span>
      </button>
      <!-- <RouterLink to="/" class="logout">
        <span class="material-symbols-outlined"> lock </span>
        <span>{{ t("common.logout") }}</span>
      </RouterLink> -->
    </div>
  </header>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import router from "@/router";
const { t } = useI18n();
const store = useStore();

function changeMenu() {
  store.commit("app/UPDATE_SIDEBARSTATUS");
}

function logout() {
  store.commit("app/RESET_INFO");
}

// export default {
//   components: {},
//   data() {},
//   methods: {
//     isMatch(path) {
//       if (!path) return false;
//       return path.test(this.$route.fullPath);
//     },
//     toAbout() {
//       this.$router.push("/about");
//     },
//   },
// };
</script>

<style lang="scss" scoped>
header {
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  background-color: #3c8dbc;

  .menu {
    background-color: transparent;
    border: none;
    width: 50px;
    line-height: 60px;
    color: #fff;
  }

  .funcBtn {
    display: flex;
    line-height: 50px;
    font-size: 24px;
    color: #fff;
    user-select: none;
    div {
      margin: auto 10px;
      color: #fff;
      opacity: 0.85;
    }
    .logout {
      margin: auto 20px auto 10px;
      color: #fff;
      display: flex;
      flex-direction: row;
      text-decoration-line: none;
      background-color: transparent;
      border: none;

      span {
        font-size: 18px;
        line-height: 50px;
        margin-right: 2px;
      }
    }
  }
}
</style>
