<template>
  <div class="login-box-body">
    <form method="post" target="hid_proc" :model="formData" @submit="Submit">
      <div class="form-group">
        <input
          type="text"
          v-model="formData.account"
          name="account"
          class="form-control"
          :placeholder="t('board.write.placeholder.id')"
          required
        />
        <span class="material-symbols-outlined">person</span>
      </div>
      <div class="form-group">
        <input
          type="password"
          v-model="formData.password"
          name="password"
          class="form-control"
          :placeholder="t('board.write.placeholder.pwd')"
          required
        />
        <span class="material-symbols-outlined">Lock</span>
      </div>
      <div>
        <label class="caption" v-if="captionMessage">{{
          captionMessage
        }}</label>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <button type="submit" class="btn btn-primary">
            {{ t("common.login") }}
          </button>
        </div>
        <!-- /.col -->
      </div>
    </form>
  </div>
  <!-- /.login-box-body -->
</template>

<script lang="ts" setup>
import { reactive, ref, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import router from "@/router";
const store = useStore();
const { t } = useI18n();

const formData = reactive({
  account: "",
  password: "",
});

const captionMessage = ref("");

async function Submit(e: Event) {
  e.preventDefault();
  const login = await store.dispatch("app/login", {
    account: formData.account,
    password: formData.password,
  });

  if (login.result) {
    //router.go(0);
    router.push("/adminlist");
  } else {
    captionMessage.value = login.message;
  }
}
</script>

<style lang="scss" scoped>
.login-box-body {
  // background: #d2d6de;
  background-color: #fff;
  padding: 20px;
  border-top: 0;
  color: #666;

  div {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .form-group {
    position: relative;

    span {
      position: absolute;
      top: 5px;
      right: 7px;
    }
  }
  .caption {
    color: red;
    font-weight: bold;
  }

  button {
    width: 100%;
    color: #fff;
  }
}

.login-box-body .form-control-feedback {
  color: #777;
}
</style>
