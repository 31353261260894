<template id="">
  <LoginView v-if="loginFlag" class="login" />
  <div v-else class="main">
    <div class="main-left">
      <SideBar />
    </div>
    <div class="main-right">
      <Header />
      <div class="content">
        <RouterView />
      </div>
      <Footer />
    </div>
  </div>
  <LoadingSPinner v-if="!isLoading" />
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { computed, watch } from "vue";
import Header from "@/components/Application/AppHeader.vue";
import SideBar from "@/components/Application/AppSideBar.vue";
import Footer from "@/components/Application/AppFooter.vue";
import LoginView from "@/views/sys/login/LoginView.vue";
import LoadingSPinner from "@/components/Application/AppLoadingSpinner.vue";
import store from "@/store/index";

const { t } = useI18n();
const loginFlag = computed(() => {
  return store.getters["app/myAccessToken"] == "";
});
const isLoading = computed(() => {
  return store.getters["app/appLoading"] == "";
});
</script>

<style lang="scss">
html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  background-color: #222d32;
}

#app {
  font-family: "Noto Sans KR", sans-serif;
  background-color: #d2d6de;

  .login {
  }

  .main {
    display: flex;
    flex-direction: row;
    height: 100%;
    max-height: fit-content;
    .main-left {
      font-weight: 700;
    }

    .main-right {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      max-height: fit-content;
      overflow: auto;

      .content {
        flex: 1;
        background-color: #ecf0f5;
      }
    }
  }
}
</style>
