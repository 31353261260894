<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div :class="'title ' + (sidebarStatus ? 'small' : '')">
    {{ t("info.title") }}
  </div>
  <div
    :class="'user-panel ' + (sidebarStatus ? 'small' : '')"
    :style="sidebarStatus ? 'display:none' : ''"
  >
    <div class="pull-left image">
      <img src="@/assets/images/menu.png" class="img-circle" />
    </div>
    <div class="pull-left info">
      <p>{{ adminName }}</p>
    </div>
  </div>
  <VueAwesomeSideBar
    v-model:miniMenu="sidebarStatus"
    :menu="menuList"
    width="270px"
    position="relati"
    :dark="true"
    :BottomMiniMenuBtn="false"
    paddingTop="0px"
    height="100%"
    vue-router-enabel
  />
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const store = useStore();

const menuList = computed(() => {
  return [
    {
      header: "Menu List",
    },
    {
      name: t("route.P_RootManagent"),
      icon: { text: "Person", class: "material-symbols-outlined" },
      children: [
        {
          name: t("route.C_AdminList"),
          href: "/adminlist",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
        {
          name: t("route.C_Notice"),
          href: "/notice",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
        {
          name: t("route.C_ChainInfo"),
          href: "/chaininfo",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
        {
          name: t("route.C_TokenInfo"),
          href: "/tokeninfo",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
        {
          name: t("route.C_Memo"),
          href: "/memo",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
        {
          name: t("route.C_UserList"),
          href: "/userlist",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
      ],
    },
    // {
    //   name: t("route.P_GroupManagent"),
    //   icon: { text: "Person", class: "material-symbols-outlined" },
    //   children: [
    //     {
    //       name: t("route.C_Group_UserList"),
    //       href: "/group_user_list",
    //       icon: { text: "Circle", class: "material-symbols-outlined" },
    //     },
    //   ],
    // },
    // {
    //   name: t("route.P_BuyCoins"),
    //   icon: { text: "Person", class: "material-symbols-outlined" },
    //   children: [
    //     {
    //       name: t("route.C_UserTokensList"),
    //       href: "/usertokens_list",
    //       icon: { text: "Circle", class: "material-symbols-outlined" },
    //     },
    //     {
    //       name: t("route.C_WithdrwalHistory"),
    //       href: "/withdrawal_history",
    //       icon: { text: "Circle", class: "material-symbols-outlined" },
    //     },
    //   ],
    // },
    {
      name: t("route.P_AppManagent"),
      icon: { text: "Person", class: "material-symbols-outlined" },
      children: [
        {
          name: t("route.C_Banner"),
          href: "/banner",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
        {
          name: t("route.C_Category"),
          href: "/category",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
        {
          name: t("route.C_NftInfo"),
          href: "/nft_info",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
        {
          name: t("route.C_UserPoint"),
          href: "/user_point",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
        {
          name: t("route.C_UserNftList"),
          href: "/user_nft",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
        // {
        //   name: t("route.C_UserGameTokenList"),
        //   href: "/user_gametoken",
        //   icon: { text: "Circle", class: "material-symbols-outlined" },
        // },
        {
          name: t("route.C_UserRewardsList"),
          href: "/user_reward",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
        // 추가해주는 내용//
        {
          name: t("route.C_InfoReferral"),
          href: "/InfoReferral",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
        {
          name: t("route.C_UserReferral"),
          href: "/UserReferral",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
      ],
    },
    {
      name: t("route.P_TransmissionDetails"),
      icon: { text: "Person", class: "material-symbols-outlined" },
      children: [
        {
          name: t("route.C_TransitionHistory"),
          href: "/transition_history",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
        {
          name: t("route.C_SwapTransactions"),
          href: "/swap_transactions",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
      ],
    },
    {
      name: t("route.P_Config"),
      icon: { text: "Person", class: "material-symbols-outlined" },
      children: [
        // {
        //   name: t("route.C_AccountInfo"),
        //   href: "/account_info",
        //   icon: { text: "Circle", class: "material-symbols-outlined" },
        // },
        {
          name: t("route.C_Password"),
          href: "/password",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
      ],
    },

    {
      name: t("route.Battle"),
      icon: { text: "Person", class: "material-symbols-outlined" },
      children: [
        {
          name: t("route.BattleSession"),
          href: "/battleSession",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
        {
          name: t("route.BattleContents"),
          href: "/battleContents",
          icon: { text: "Circle", class: "material-symbols-outlined" },
        },
      ],
    },
  ];
});

const sidebarStatus = computed(() => {
  return store.state.app.sidebarStatus;
});

const adminName = computed(() => {
  return store.state.app.info.name;
});
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  height: 50px;
  line-height: 50px;
  background-color: #3c8dbc;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  border-right: 1px solid;
  transition: width 0.3s;
  overflow: hidden;
  z-index: 850;
  &.small {
    width: 65px;
  }
}

.user-panel {
  height: 50px;
  z-index: 900;
  color: #fff;
  position: relative;
  background-color: #333;
  margin: auto;
  display: flex;

  .pull-left {
    height: 100%;

    &.image {
      height: 75%;
      width: fit-content;
      display: flex;
      margin: 3% 0 3% 5%;
    }
    &.info {
      padding-left: 20px;
      p {
        line-height: 50px;
      }
    }
  }
}
</style>

<style lang="scss">
.vas-menu {
  &.ltr {
    height: calc(100% - 100px) !important;
  }
  .miniActive {
    background-color: #2c3b41 !important;
  }
}

.small {
  + .vas-menu {
    &.ltr {
      height: calc(100% - 50px) !important;
    }
  }
}
</style>
